$(function () {
	$(document).on('click', '#NavOpen', function () {
		$("#Nav").toggleClass('is-active');
		$("#NavOpen").toggleClass('is-active');
		$(".Header--SNS").toggleClass('is-active');
		$(".TopPage--MainImage .SNS").toggleClass('is-active');

	})
	if ($(window).width() <= 640) {
		$("#Footer").css({
			"margin-bottom": $("#FooterNav").outerHeight()
		})
		$("#FooterNav").css({
			"bottom": $("#FooterNav").outerHeight() * -1
		})

	}

	if ($(".Header.InPage").length) {
		$("body").css({
			"padding-top": $(".Header.InPage").outerHeight()
		})
	}
	if ($("#Information").length) {
		$("#Information").css({
			"min-height": $(window).height() - $("#Header").outerHeight() - $("#Footer").outerHeight() - $(".PageTop").outerHeight()
		})
	}
	$(document).on('click', '#TopPage .Global li a', function () {
		// console.log("a");
		$link = $($(this).attr("href"));
		$("#Nav").toggleClass('is-active');
		$("#NavOpen").toggleClass('is-active');
		$(".Header--SNS").toggleClass('is-active');
		$("html, body").animate({
			scrollTop: $link.offset().top - $('#Header').outerHeight()
		}, 2000)
	})


})

$(window).on('scroll', function () {
	if (!$(".Header.InPage").length) {
		if ($(this).scrollTop() >= $(window).height()) {
			$('#Header').addClass('is-active');
		} else {
			$('#Header').removeClass('is-active');
		}
	}
	if ($(this).scrollTop() >= $(window).height()) {
		$('#FooterNav').addClass('is-active');
	} else {
		$('#FooterNav').removeClass('is-active');
	}
})


$(window).on('resize', function () {

	if ($(window).width() <= 640) {
		$("#Footer").css({
			"margin-bottom": $("#FooterNav").outerHeight()
		})
	} else {
		$("#Footer").css({
			"margin-bottom": 0
		})
	}

	if ($(".Header.InPage").length) {
		$("body").css({
			"padding-top": $(".Header.InPage").outerHeight()
		})
	}
	if ($("#Information").length) {
		$("#Information").css({
			"min-height": $(window).height() - $("#Header").outerHeight() - $("#Footer").outerHeight() - $(".PageTop").outerHeight()
		})
	}

});
