const moment = require('moment');
import Swiper, { Navigation, Pagination, EffectFade } from 'swiper';
import AOS from "aos";
import inview from "jquery-inview";
import SimpleBar from 'simplebar';

/**
 * 単純なサンプル
 * moment.jsを使って現在日付をconsole出力するだけ
 */
$(function () {
    AOS.init();
    $('.MessageBox .Body').each(function (index, el) { new SimpleBar(el) });


    $(window).on('load');
    $('.TopPage--Loader').addClass('Open');
    setTimeout(function () {
        $('.TopPage--Loader').css('display', 'none');
    }, 2000);

    var swiper = new Swiper('.swiper-container', {
        modules: [Navigation, Pagination, EffectFade],
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
        },
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        autoplay: {
            delay: 5000,
        },
        speed: 500,
        loop: true
    });
    $('.Mask').on('inview', function (event, isInView) {
        if (isInView) {
            $(this).addClass("is-active");
            setTimeout(() => {
                $(this).remove()
            }, 2100);
        }
    });
    $(document).on('click', '#TopPagePractitioner .Item .Btn', function () {
        $("+.MessageBox", this).fadeIn();
        $(".Overlay").fadeIn();
    });
    $(document).on('click', '#TopPagePractitioner .MessageBox .Close', function () {
        $(this).parent(".MessageBox,.Overlay").fadeOut();
        $(".Overlay").fadeOut();
    });


    $(document).on('click', '#LineBox', function () {
        $(".TopPage--Line__Box,.Overlay").fadeIn();
        return false;
    });
    $(document).on('click', '.TopPage--Line__Box .Close', function () {
        $(".TopPage--Line__Box,.Overlay").fadeOut();
    });

    $(document).on('click', '#TelBox', function () {
        $(".TopPage--Contact__TelBox,.Overlay").fadeIn();
        return false;
    });
    $(document).on('click', '.TopPage--Contact__TelBox .Close', function () {
        $(".TopPage--Contact__TelBox,.Overlay").fadeOut();
    });

});
